export default [
  {
    path: '/tests/listening/list/free',
    name: 'listening-list-free',
    component: () => import('@/views/tests/listening/list/ListeningListFree.vue'),
  },
  {
    path: '/tests/listening/list/premium',
    name: 'listening-list-premium',
    component: () => import('@/views/tests/listening/list/ListeningListPremium.vue'),
  },
]
