export default [
  {
    path: '/tests/reading/list/free',
    name: 'reading-list-free',
    component: () => import('@/views/tests/reading/list/ReadingListFree.vue'),
  },
  {
    path: '/tests/reading/list/premium',
    name: 'reading-list-premium',
    component: () => import('@/views/tests/reading/list/ReadingListPremium.vue'),
  },
  {
    path: '/tests/reading/add',
    name: 'reading-add',
    component: () => import('@/views/tests/reading/add/ReadingAdd.vue'),
  },
]
