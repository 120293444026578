import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'
/* eslint-disable global-require */
const data = {
  list: [
    {
      id: 1,
      title: 'Lời khuyên luyện thi IELTS online hiệu quả dành cho các sĩ tử',
      content: '<p>Luyện thi IELTS trực tuyến hay còn gọi là luyện thi IELTS online là một sự lựa chọn thông minh, khoa học và tiết kiệm đối với hầu hết các sĩ tử luyện thi IELTS. Nhưng để luyện thi IELTS online hiệu quả, các sĩ tử cần có kế hoạch, sự quyết tâm và chọn được chương trình tốt. Bài phân tích dưới đây của các chuyên gia IELTS tại Smartcom English sẽ giúp cho các sĩ tử vận dụng tối đa ưu điểm, khắc phục được nhược điểm của luyện thi IELTS online.</p>',
      summary: '',
      avatar_url: 'https://www.smartcom.vn/wp-content/uploads/2022/05/loikhuyenluyenieltshieuqua_image1.png',
      slug: 'loi-khuyen-luyen-thi-ielt-online',
      author: 'Guest user',
      created_on: '2022-05-19T22:43:41',
      updated_on: '2022-05-19T22:59:11',
    },
    {
      id: 2,
      title: 'Lời khuyên luyện thi IELTS online hiệu quả dành cho các sĩ tử',
      content: '<p>Luyện thi IELTS trực tuyến hay còn gọi là luyện thi IELTS online là một sự lựa chọn thông minh, khoa học và tiết kiệm đối với hầu hết các sĩ tử luyện thi IELTS. Nhưng để luyện thi IELTS online hiệu quả, các sĩ tử cần có kế hoạch, sự quyết tâm và chọn được chương trình tốt. Bài phân tích dưới đây của các chuyên gia IELTS tại Smartcom English sẽ giúp cho các sĩ tử vận dụng tối đa ưu điểm, khắc phục được nhược điểm của luyện thi IELTS online.</p>',
      summary: '',
      avatar_url: 'https://www.smartcom.vn/wp-content/uploads/2022/05/loikhuyenluyenieltshieuqua_image1.png',
      slug: 'loi-khuyen-luyen-thi-ielt-online',
      author: 'Guest user',
      created_on: '2022-05-19T22:43:41',
      updated_on: '2022-05-19T22:59:11',
    },
    {
      id: 3,
      title: 'Lời khuyên luyện thi IELTS online hiệu quả dành cho các sĩ tử',
      content: '<p>Luyện thi IELTS trực tuyến hay còn gọi là luyện thi IELTS online là một sự lựa chọn thông minh, khoa học và tiết kiệm đối với hầu hết các sĩ tử luyện thi IELTS. Nhưng để luyện thi IELTS online hiệu quả, các sĩ tử cần có kế hoạch, sự quyết tâm và chọn được chương trình tốt. Bài phân tích dưới đây của các chuyên gia IELTS tại Smartcom English sẽ giúp cho các sĩ tử vận dụng tối đa ưu điểm, khắc phục được nhược điểm của luyện thi IELTS online.</p>',
      summary: '',
      avatar_url: 'https://www.smartcom.vn/wp-content/uploads/2022/05/loikhuyenluyenieltshieuqua_image1.png',
      slug: 'loi-khuyen-luyen-thi-ielt-online',
      author: 'Guest user',
      created_on: '2022-05-19T22:43:41',
      updated_on: '2022-05-19T22:59:11',
    },
    {
      id: 4,
      title: 'Lời khuyên luyện thi IELTS online hiệu quả dành cho các sĩ tử',
      content: '<p>Luyện thi IELTS trực tuyến hay còn gọi là luyện thi IELTS online là một sự lựa chọn thông minh, khoa học và tiết kiệm đối với hầu hết các sĩ tử luyện thi IELTS. Nhưng để luyện thi IELTS online hiệu quả, các sĩ tử cần có kế hoạch, sự quyết tâm và chọn được chương trình tốt. Bài phân tích dưới đây của các chuyên gia IELTS tại Smartcom English sẽ giúp cho các sĩ tử vận dụng tối đa ưu điểm, khắc phục được nhược điểm của luyện thi IELTS online.</p>',
      summary: '',
      avatar_url: 'https://www.smartcom.vn/wp-content/uploads/2022/05/loikhuyenluyenieltshieuqua_image1.png',
      slug: 'loi-khuyen-luyen-thi-ielt-online',
      author: 'Guest user',
      created_on: '2022-05-19T22:43:41',
      updated_on: '2022-05-19T22:59:11',
    },
    {
      id: 5,
      title: 'Lời khuyên luyện thi IELTS online hiệu quả dành cho các sĩ tử',
      content: '<p>Luyện thi IELTS trực tuyến hay còn gọi là luyện thi IELTS online là một sự lựa chọn thông minh, khoa học và tiết kiệm đối với hầu hết các sĩ tử luyện thi IELTS. Nhưng để luyện thi IELTS online hiệu quả, các sĩ tử cần có kế hoạch, sự quyết tâm và chọn được chương trình tốt. Bài phân tích dưới đây của các chuyên gia IELTS tại Smartcom English sẽ giúp cho các sĩ tử vận dụng tối đa ưu điểm, khắc phục được nhược điểm của luyện thi IELTS online.</p>',
      summary: '',
      avatar_url: 'https://www.smartcom.vn/wp-content/uploads/2022/05/loikhuyenluyenieltshieuqua_image1.png',
      slug: 'loi-khuyen-luyen-thi-ielt-online',
      author: 'Guest user',
      created_on: '2022-05-19T22:43:41',
      updated_on: '2022-05-19T22:59:11',
    },
    {
      id: 6,
      title: 'Lời khuyên luyện thi IELTS online hiệu quả dành cho các sĩ tử',
      content: '<p>Luyện thi IELTS trực tuyến hay còn gọi là luyện thi IELTS online là một sự lựa chọn thông minh, khoa học và tiết kiệm đối với hầu hết các sĩ tử luyện thi IELTS. Nhưng để luyện thi IELTS online hiệu quả, các sĩ tử cần có kế hoạch, sự quyết tâm và chọn được chương trình tốt. Bài phân tích dưới đây của các chuyên gia IELTS tại Smartcom English sẽ giúp cho các sĩ tử vận dụng tối đa ưu điểm, khắc phục được nhược điểm của luyện thi IELTS online.</p>',
      summary: '',
      avatar_url: 'https://www.smartcom.vn/wp-content/uploads/2022/05/loikhuyenluyenieltshieuqua_image1.png',
      slug: 'loi-khuyen-luyen-thi-ielt-online',
      author: 'Guest user',
      created_on: '2022-05-19T22:43:41',
      updated_on: '2022-05-19T22:59:11',
    },
  ],
}
/* eslint-disable global-require */
mock.onGet('/blog/list').reply(config => {
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'id',
    sortDesc = false,
    status = null,
  } = config.params

  const queryLowered = q.toLowerCase()
  const filteredData = data.list.filter(
    blog => (blog.title.toLowerCase().includes(queryLowered))
          && blog.status === (status || blog.status),
  )
  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()
  return [200, {
    list: paginateArray(sortedData, perPage, page),
    total: filteredData.length,
  }]
})
