export default [
  {
    path: '/tests/speaking/list/free',
    name: 'speaking-list-free',
    component: () => import('@/views/tests/speaking/list/SpeakingListFree.vue'),
  },
  {
    path: '/tests/speaking/list/premium',
    name: 'speaking-list-premium',
    component: () => import('@/views/tests/speaking/list/SpeakingListPremium.vue'),
  },
  {
    path: '/tests/speaking/add',
    name: 'speaking-add',
    component: () => import('@/views/tests/speaking/add/SpeakingAdd.vue'),
  },
  {
    path: '/tests/speaking/edit/:id',
    name: 'speaking-edit',
    component: () => import('@/views/tests/speaking/edit/SpeakingEdit.vue'),
  },
]
