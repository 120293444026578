import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  list: [
    {
      id: 1,
      code: 'R1',
      title: 'Free online IELTS Academic Reading practice test - paper',
      plan: 'FREE',
      status: 'draft',
      reading_sections: [
        {
          id: 1,
          title: 'Academic Reading - section 1 practice test',
          description: 'You should spend about 20 minutes on Questions 1–13, which are based on Reading Passage 1 below.',
          reading_content: '<p>There is much that we do not yet know concerning how electroreception functions. Although researchers have documented how electroreception alters hunting, defence and communication systems through observation, the exact neurological processes that encode and decode this information are unclear. Scientists are also exploring the role electroreception plays in navigation. Some have proposed that salt water and magnetic fields from the Earth’s core may interact to form electrical currents that sharks use for migratory purposes</p>',
          reading_question_groups: [
            {
              id: 1,
              title: 'update',
              description: 'Reading Passage 1 has eight paragraphs, A–H.',
              reading_single_questions: [
                {
                  id: 1,
                  content: 'Which paragraph contains the following information?',
                  question_type: 'TRUE_FALSE_NOT_GIVEN',
                  correct_answer: '1. how electroreception can be used to help fish reproduce',
                },
              ],
            },
          ],
        },
      ],
      created_on: '2022-05-19T11:03:48',
      updated_on: '2022-05-19T11:06:35',
    },
    {
      id: 3,
      code: 'R1',
      title: 'Premium IELTS Academic Reading practice test - paper',
      plan: 'PREMIUM',
      status: 'draft',
      reading_sections: [
        {
          id: 3,
          title: 'Academic Reading - section 1 practice test',
          description: 'You should spend about 20 minutes on Questions 1–13, which are based on Reading Passage 1 below.',
          reading_content: '<p>There is much that we do not yet know concerning how electroreception functions. Although researchers have documented how electroreception alters hunting, defence and communication systems through observation, the exact neurological processes that encode and decode this information are unclear. Scientists are also exploring the role electroreception plays in navigation. Some have proposed that salt water and magnetic fields from the Earth’s core may interact to form electrical currents that sharks use for migratory purposes</p>',
          reading_question_groups: [
            {
              id: 3,
              title: 'Questions 1–6',
              description: 'Reading Passage 1 has eight paragraphs, A–H.',
              reading_single_questions: [
                {
                  id: 3,
                  content: 'Which paragraph contains the following information?',
                  question_type: 'TRUE_FALSE_NOT_GIVEN',
                  correct_answer: '1. how electroreception can be used to help fish reproduce',
                },
              ],
            },
          ],
        },
      ],
      created_on: '2022-05-19T11:07:24',
      updated_on: '2022-05-19T11:07:24',
    },
    {
      id: 4,
      code: 'R1',
      title: 'Free online IELTS Academic Reading practice test - paper',
      plan: 'FREE',
      status: 'active',
      reading_sections: [
        {
          id: 1,
          title: 'Academic Reading - section 1 practice test',
          description: 'You should spend about 20 minutes on Questions 1–13, which are based on Reading Passage 1 below.',
          reading_content: '<p>There is much that we do not yet know concerning how electroreception functions. Although researchers have documented how electroreception alters hunting, defence and communication systems through observation, the exact neurological processes that encode and decode this information are unclear. Scientists are also exploring the role electroreception plays in navigation. Some have proposed that salt water and magnetic fields from the Earth’s core may interact to form electrical currents that sharks use for migratory purposes</p>',
          reading_question_groups: [
            {
              id: 1,
              title: 'update',
              description: 'Reading Passage 1 has eight paragraphs, A–H.',
              reading_single_questions: [
                {
                  id: 1,
                  content: 'Which paragraph contains the following information?',
                  question_type: 'TRUE_FALSE_NOT_GIVEN',
                  correct_answer: '1. how electroreception can be used to help fish reproduce',
                },
              ],
            },
          ],
        },
      ],
      created_on: '2022-05-19T11:03:48',
      updated_on: '2022-05-19T11:06:35',
    },
    {
      id: 5,
      code: 'R1',
      title: 'Premium IELTS Academic Reading practice test - paper',
      plan: 'PREMIUM',
      status: 'draft',
      reading_sections: [
        {
          id: 3,
          title: 'Academic Reading - section 1 practice test',
          description: 'You should spend about 20 minutes on Questions 1–13, which are based on Reading Passage 1 below.',
          reading_content: '<p>There is much that we do not yet know concerning how electroreception functions. Although researchers have documented how electroreception alters hunting, defence and communication systems through observation, the exact neurological processes that encode and decode this information are unclear. Scientists are also exploring the role electroreception plays in navigation. Some have proposed that salt water and magnetic fields from the Earth’s core may interact to form electrical currents that sharks use for migratory purposes</p>',
          reading_question_groups: [
            {
              id: 3,
              title: 'Questions 1–6',
              description: 'Reading Passage 1 has eight paragraphs, A–H.',
              reading_single_questions: [
                {
                  id: 3,
                  content: 'Which paragraph contains the following information?',
                  question_type: 'TRUE_FALSE_NOT_GIVEN',
                  correct_answer: '1. how electroreception can be used to help fish reproduce',
                },
              ],
            },
          ],
        },
      ],
      created_on: '2022-05-19T11:07:24',
      updated_on: '2022-05-19T11:07:24',
    },
    {
      id: 6,
      code: 'R1',
      title: 'Free online IELTS Academic Reading practice test - paper',
      plan: 'FREE',
      status: 'active',
      reading_sections: [
        {
          id: 1,
          title: 'Academic Reading - section 1 practice test',
          description: 'You should spend about 20 minutes on Questions 1–13, which are based on Reading Passage 1 below.',
          reading_content: '<p>There is much that we do not yet know concerning how electroreception functions. Although researchers have documented how electroreception alters hunting, defence and communication systems through observation, the exact neurological processes that encode and decode this information are unclear. Scientists are also exploring the role electroreception plays in navigation. Some have proposed that salt water and magnetic fields from the Earth’s core may interact to form electrical currents that sharks use for migratory purposes</p>',
          reading_question_groups: [
            {
              id: 1,
              title: 'update',
              description: 'Reading Passage 1 has eight paragraphs, A–H.',
              reading_single_questions: [
                {
                  id: 1,
                  content: 'Which paragraph contains the following information?',
                  question_type: 'TRUE_FALSE_NOT_GIVEN',
                  correct_answer: '1. how electroreception can be used to help fish reproduce',
                },
              ],
            },
          ],
        },
      ],
      created_on: '2022-05-19T11:03:48',
      updated_on: '2022-05-19T11:06:35',
    },
    {
      id: 7,
      code: 'R1',
      title: 'Premium IELTS Academic Reading practice test - paper',
      plan: 'PREMIUM',
      status: 'draft',
      reading_sections: [
        {
          id: 3,
          title: 'Academic Reading - section 1 practice test',
          description: 'You should spend about 20 minutes on Questions 1–13, which are based on Reading Passage 1 below.',
          reading_content: '<p>There is much that we do not yet know concerning how electroreception functions. Although researchers have documented how electroreception alters hunting, defence and communication systems through observation, the exact neurological processes that encode and decode this information are unclear. Scientists are also exploring the role electroreception plays in navigation. Some have proposed that salt water and magnetic fields from the Earth’s core may interact to form electrical currents that sharks use for migratory purposes</p>',
          reading_question_groups: [
            {
              id: 3,
              title: 'Questions 1–6',
              description: 'Reading Passage 1 has eight paragraphs, A–H.',
              reading_single_questions: [
                {
                  id: 3,
                  content: 'Which paragraph contains the following information?',
                  question_type: 'TRUE_FALSE_NOT_GIVEN',
                  correct_answer: '1. how electroreception can be used to help fish reproduce',
                },
              ],
            },
          ],
        },
      ],
      created_on: '2022-05-19T11:07:24',
      updated_on: '2022-05-19T11:07:24',
    },
    {
      id: 8,
      code: 'R1',
      title: 'Free online IELTS Academic Reading practice test - paper',
      plan: 'FREE',
      status: 'active',
      reading_sections: [
        {
          id: 1,
          title: 'Academic Reading - section 1 practice test',
          description: 'You should spend about 20 minutes on Questions 1–13, which are based on Reading Passage 1 below.',
          reading_content: '<p>There is much that we do not yet know concerning how electroreception functions. Although researchers have documented how electroreception alters hunting, defence and communication systems through observation, the exact neurological processes that encode and decode this information are unclear. Scientists are also exploring the role electroreception plays in navigation. Some have proposed that salt water and magnetic fields from the Earth’s core may interact to form electrical currents that sharks use for migratory purposes</p>',
          reading_question_groups: [
            {
              id: 1,
              title: 'update',
              description: 'Reading Passage 1 has eight paragraphs, A–H.',
              reading_single_questions: [
                {
                  id: 1,
                  content: 'Which paragraph contains the following information?',
                  question_type: 'TRUE_FALSE_NOT_GIVEN',
                  correct_answer: '1. how electroreception can be used to help fish reproduce',
                },
              ],
            },
          ],
        },
      ],
      created_on: '2022-05-19T11:03:48',
      updated_on: '2022-05-19T11:06:35',
    },
    {
      id: 9,
      code: 'R1',
      title: 'Premium IELTS Academic Reading practice test - paper',
      plan: 'PREMIUM',
      status: 'draft',
      reading_sections: [
        {
          id: 3,
          title: 'Academic Reading - section 1 practice test',
          description: 'You should spend about 20 minutes on Questions 1–13, which are based on Reading Passage 1 below.',
          reading_content: '<p>There is much that we do not yet know concerning how electroreception functions. Although researchers have documented how electroreception alters hunting, defence and communication systems through observation, the exact neurological processes that encode and decode this information are unclear. Scientists are also exploring the role electroreception plays in navigation. Some have proposed that salt water and magnetic fields from the Earth’s core may interact to form electrical currents that sharks use for migratory purposes</p>',
          reading_question_groups: [
            {
              id: 3,
              title: 'Questions 1–6',
              description: 'Reading Passage 1 has eight paragraphs, A–H.',
              reading_single_questions: [
                {
                  id: 3,
                  content: 'Which paragraph contains the following information?',
                  question_type: 'TRUE_FALSE_NOT_GIVEN',
                  correct_answer: '1. how electroreception can be used to help fish reproduce',
                },
              ],
            },
          ],
        },
      ],
      created_on: '2022-05-19T11:07:24',
      updated_on: '2022-05-19T11:07:24',
    },
    {
      id: 10,
      code: 'R1',
      title: 'Free online IELTS Academic Reading practice test - paper',
      plan: 'FREE',
      status: 'active',
      reading_sections: [
        {
          id: 1,
          title: 'Academic Reading - section 1 practice test',
          description: 'You should spend about 20 minutes on Questions 1–13, which are based on Reading Passage 1 below.',
          reading_content: '<p>There is much that we do not yet know concerning how electroreception functions. Although researchers have documented how electroreception alters hunting, defence and communication systems through observation, the exact neurological processes that encode and decode this information are unclear. Scientists are also exploring the role electroreception plays in navigation. Some have proposed that salt water and magnetic fields from the Earth’s core may interact to form electrical currents that sharks use for migratory purposes</p>',
          reading_question_groups: [
            {
              id: 1,
              title: 'update',
              description: 'Reading Passage 1 has eight paragraphs, A–H.',
              reading_single_questions: [
                {
                  id: 1,
                  content: 'Which paragraph contains the following information?',
                  question_type: 'TRUE_FALSE_NOT_GIVEN',
                  correct_answer: '1. how electroreception can be used to help fish reproduce',
                },
              ],
            },
          ],
        },
      ],
      created_on: '2022-05-19T11:03:48',
      updated_on: '2022-05-19T11:06:35',
    },
    {
      id: 11,
      code: 'R1',
      title: 'Premium IELTS Academic Reading practice test - paper',
      plan: 'PREMIUM',
      status: 'active',
      reading_sections: [
        {
          id: 3,
          title: 'Academic Reading - section 1 practice test',
          description: 'You should spend about 20 minutes on Questions 1–13, which are based on Reading Passage 1 below.',
          reading_content: '<p>There is much that we do not yet know concerning how electroreception functions. Although researchers have documented how electroreception alters hunting, defence and communication systems through observation, the exact neurological processes that encode and decode this information are unclear. Scientists are also exploring the role electroreception plays in navigation. Some have proposed that salt water and magnetic fields from the Earth’s core may interact to form electrical currents that sharks use for migratory purposes</p>',
          reading_question_groups: [
            {
              id: 3,
              title: 'Questions 1–6',
              description: 'Reading Passage 1 has eight paragraphs, A–H.',
              reading_single_questions: [
                {
                  id: 3,
                  content: 'Which paragraph contains the following information?',
                  question_type: 'TRUE_FALSE_NOT_GIVEN',
                  correct_answer: '1. how electroreception can be used to help fish reproduce',
                },
              ],
            },
          ],
        },
      ],
      created_on: '2022-05-19T11:07:24',
      updated_on: '2022-05-19T11:07:24',
    },
    {
      id: 12,
      code: 'R1',
      title: 'Premium IELTS Academic Reading practice test - paper',
      plan: 'PREMIUM',
      status: 'draft',
      reading_sections: [
        {
          id: 3,
          title: 'Academic Reading - section 1 practice test',
          description: 'You should spend about 20 minutes on Questions 1–13, which are based on Reading Passage 1 below.',
          reading_content: '<p>There is much that we do not yet know concerning how electroreception functions. Although researchers have documented how electroreception alters hunting, defence and communication systems through observation, the exact neurological processes that encode and decode this information are unclear. Scientists are also exploring the role electroreception plays in navigation. Some have proposed that salt water and magnetic fields from the Earth’s core may interact to form electrical currents that sharks use for migratory purposes</p>',
          reading_question_groups: [
            {
              id: 3,
              title: 'Questions 1–6',
              description: 'Reading Passage 1 has eight paragraphs, A–H.',
              reading_single_questions: [
                {
                  id: 3,
                  content: 'Which paragraph contains the following information?',
                  question_type: 'TRUE_FALSE_NOT_GIVEN',
                  correct_answer: '1. how electroreception can be used to help fish reproduce',
                },
              ],
            },
          ],
        },
      ],
      created_on: '2022-05-19T11:07:24',
      updated_on: '2022-05-19T11:07:24',
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/readings/free').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'id',
    sortDesc = false,
    plan = null,
    status = null,
  } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.list.filter(
    test =>
    /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (test.title.toLowerCase().includes(queryLowered)) &&
            test.plan === (plan || test.plan) &&
            test.status === (status || test.status),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()
  return [
    200,
    {
      list: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

mock.onGet('/readings/premium').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'id',
    sortDesc = false,
    plan = null,
    status = null,
  } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.list.filter(
    test =>
    /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (test.title.toLowerCase().includes(queryLowered)) &&
            test.plan === (plan || test.plan) &&
            test.status === (status || test.status),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()
  return [
    200,
    {
      list: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// POST: Add new readings
// ------------------------------------------------
mock.onPost('/readings/free').reply(config => {
  // Get event from post data
  const { task } = JSON.parse(config.data)

  const { length } = data.tasks
  let lastIndex = 0
  if (length) {
    lastIndex = data.tasks[length - 1].id
  }
  task.id = lastIndex + 1

  data.tasks.push(task)

  return [201, { task }]
})
