import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  users: [
    {
      id: 1,
      fullName: 'Nguyễn Duy Anh',
      company: 'Yotz PVT LTD',
      role: 'ROLE_ADMIN',
      username: 'gslixby0',
      country: 'El Salvador',
      contact: '(479) 232-9151',
      email: 'gslixby0@smartcom.vn',
      status: 'inactive',
      avatar: '',
    },
    {
      id: 2,
      fullName: 'Nguyễn Thu Trang',
      company: 'Skinder PVT LTD',
      role: 'ROLE_USER_PREMIUM',
      username: 'hredmore1',
      country: 'Albania',
      contact: '(472) 607-9137',
      email: 'hredmore1@smartcom.vn',
      status: 'block',
      avatar: require('@/assets/images/avatars/10.png'),
    },
    {
      id: 3,
      fullName: 'Vũ Thái Dương',
      company: 'Oozz PVT LTD',
      role: 'ROLE_ADMIN',
      username: 'msicely2',
      country: 'Russia',
      contact: '(321) 264-4599',
      email: 'msicely2@smartcom.vn',
      status: 'active',
      avatar: require('@/assets/images/avatars/1.png'),
    },
    {
      id: 4,
      fullName: 'Nguyễn Mai Anh',
      company: 'Oozz PVT LTD',
      role: 'ROLE_ADMIN',
      username: 'crisby3',
      country: 'China',
      contact: '(923) 690-6806',
      email: 'crisby3@smartcom.vn',
      status: 'inactive',
      avatar: require('@/assets/images/avatars/9.png'),
    },
    {
      id: 5,
      fullName: 'Nguyễn Đức Nghĩa',
      company: 'Aimbo PVT LTD',
      role: 'ROLE_USER_FREE',
      username: 'mhurran4',
      country: 'Pakistan',
      contact: '(669) 914-1078',
      email: 'mhurran4@smartcom.vn',
      status: 'block',
      avatar: require('@/assets/images/avatars/10.png'),
    },
    {
      id: 6,
      fullName: 'Đặng Tú Uyên',
      company: 'Jaxbean PVT LTD',
      role: 'ROLE_USER_PREMIUM',
      username: 'shalstead5',
      country: 'China',
      contact: '(958) 973-3093',
      email: 'shalstead5@smartcom.vn',
      status: 'active',
      avatar: '',
    },
    {
      id: 7,
      fullName: 'Trần Việt Tùng',
      company: 'Jazzy PVT LTD',
      role: 'ROLE_USER_FREE',
      username: 'bgallemore6',
      country: 'Canada',
      contact: '(825) 977-8152',
      email: 'bgallemore6@smartcom.vn',
      status: 'block',
      avatar: '',
    },
    {
      id: 8,
      fullName: 'Nguyễn Mạnh Đức',
      company: 'Pixoboo PVT LTD',
      role: 'ROLE_USER_PREMIUM',
      username: 'kliger7',
      country: 'France',
      contact: '(187) 440-0934',
      email: 'kliger7@smartcom.vn',
      status: 'block',
      avatar: require('@/assets/images/avatars/9.png'),
    },
    {
      id: 9,
      fullName: 'Lùng Vinh Quang',
      company: 'Tekfly PVT LTD',
      role: 'ROLE_USER_FREE',
      username: 'fscotfurth8',
      country: 'China',
      contact: '(978) 146-5443',
      email: 'fscotfurth8@smartcom.vn',
      status: 'block',
      avatar: require('@/assets/images/avatars/2.png'),
    },
    {
      id: 10,
      fullName: 'Bùi Minh Khuê',
      company: 'Gigashots PVT LTD',
      role: 'ROLE_ADMIN',
      username: 'jbellany9',
      country: 'Jamaica',
      contact: '(589) 284-6732',
      email: 'jbellany9@smartcom.vn',
      status: 'inactive',
      avatar: require('@/assets/images/avatars/9.png'),
    },
    {
      id: 11,
      fullName: 'Trần Nhật Hoàng',
      company: 'Eare PVT LTD',
      role: 'ROLE_USER_FREE',
      username: 'jwharltona',
      country: 'United States',
      contact: '(176) 532-6824',
      email: 'jwharltona@smartcom.vn',
      status: 'inactive',
      avatar: require('@/assets/images/avatars/4.png'),
    },
    {
      id: 12,
      fullName: 'Đặng Nhật Minh',
      company: 'Yakitri PVT LTD',
      role: 'ROLE_USER_FREE',
      username: 'shallamb',
      country: 'Peru',
      contact: '(234) 464-0600',
      email: 'shallamb@smartcom.vn',
      status: 'block',
      avatar: require('@/assets/images/avatars/5.png'),
    },
    {
      id: 13,
      fullName: 'Nguyễn Ngọc Hiển',
      company: 'Leenti PVT LTD',
      role: 'ROLE_USER_FREE',
      username: 'ypottiec',
      country: 'Philippines',
      contact: '(907) 284-5083',
      email: 'ypottiec@smartcom.vn',
      status: 'inactive',
      avatar: require('@/assets/images/avatars/7.png'),
    },
    {
      id: 14,
      fullName: 'Nguyễn Hồng Khánh Vân',
      company: 'Digitube PVT LTD',
      role: 'ROLE_USER_PREMIUM',
      username: 'mkraused',
      country: 'Democratic Republic of the Congo',
      contact: '(167) 135-7392',
      email: 'mkraused@smartcom.vn',
      status: 'active',
      avatar: require('@/assets/images/avatars/9.png'),
    },
    {
      id: 15,
      fullName: 'Lê Bảo Châu',
      company: 'Kaymbo PVT LTD',
      role: 'ROLE_ADMIN',
      username: 'zmcclevertye',
      country: 'France',
      contact: '(317) 409-6565',
      email: 'zmcclevertye@smartcom.vn',
      status: 'active',
      avatar: require('@/assets/images/avatars/2.png'),
    },
    {
      id: 16,
      fullName: 'Nguyễn Duy Thái',
      company: 'Yambee PVT LTD',
      role: 'ROLE_USER_PREMIUM',
      username: 'bemblinf',
      country: 'Spain',
      contact: '(590) 606-1056',
      email: 'bemblinf@smartcom.vn',
      status: 'active',
      avatar: require('@/assets/images/avatars/6.png'),
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/apps/user/users').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'id',
    sortDesc = false,
    role = null,
    status = null,
  } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.users.filter(
    user =>
    /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (user.username.toLowerCase().includes(queryLowered) || user.fullName.toLowerCase().includes(queryLowered)) &&
          user.role === (role || user.role) &&
          user.status === (status || user.status),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      users: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// POST: Add new user
// ------------------------------------------------
mock.onPost('/apps/user/users').reply(config => {
  // Get event from post data
  const { user } = JSON.parse(config.data)

  // Assign Status
  user.status = 'active'

  const { length } = data.users
  let lastIndex = 0
  if (length) {
    lastIndex = data.users[length - 1].id
  }
  user.id = lastIndex + 1

  data.users.push(user)

  return [201, { user }]
})

// ------------------------------------------------
// GET: Return Single User
// ------------------------------------------------
mock.onGet(/\/apps\/user\/users\/\d+/).reply(config => {
  // Get event id from URL
  let userId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  userId = Number(userId)

  const userIndex = data.users.findIndex(e => e.id === userId)
  const user = data.users[userIndex]

  if (user) return [200, user]
  return [404]
})
