import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  list: [
    {
      id: 4,
      code: 'S1',
      title: '[PREMIUM] IELTS Speaking Test 05/2022',
      plan: 'PREMIUM',
      status: 'ACTIVE',
      speaking_sections: [
        {
          id: 10,
          title: 'Part 1',
          description: null,
          content: "<p><strong>Now,\n  in this first part of the test I'm going to ask you some questions about yourself.</strong></p>\n<p><strong>Are you a student or do you work now?</strong></p>\n<ol>\n  <li><strong>Why did you choose this course/job?</strong></li>\n  <li><strong>Talk about your daily routine.</strong></li>\n  <li><strong>Is there anything about your course/job you would like to change?</strong></li>\n  <li><strong>I’d like to move on and ask you some questions about shopping.</strong></li>\n</ol>\n<p><strong>Who does most of the shopping in your household?</strong></p>\n<ol>\n  <li><strong>What type of shopping do you like? (Why?)</strong></li>\n  <li><strong>Is shopping a popular activity in your country? (Why/why not?)</strong></li>\n  <li><strong>What type of shops do teenagers like best in your country?</strong></li>\n</ol>\n<p><strong>Let’s talk about films.</strong></p>\n<ol>\n  <li><strong>How often do you go to the cinema?</strong></li>\n  <li><strong>What type of films do you like best? (Why?)</strong></li>\n  <li><strong>What type of films don’t you like? (Why not?)</strong></li>\n</ol>\n<p><br></p>",
        },
        {
          id: 11,
          title: 'Part 2',
          description: 'Time: 3-4 minutes',
          content: "<p>Now, I'm\n  going to give you a topic and I'd like you to talk about it for 1-2 minutes.</p>\n  <p><br></p>\n  <p>Before you talk you'll have one minute to think about what you are going to say and you can make notes if you wish.\n    Do you understand?</p>\n  <p><br></p>\n  <p>Ok, here's some paper and a pencil to make notes, and here is your topic. I'd like you to describe something you\n    own which is very important to you.</p>\n  <p class=\"ql-indent-5\"><strong>Describe an important event in your life.</strong></p>\n  <p class=\"ql-indent-5\"><strong>You should say:</strong></p>\n  <ul>\n    <li class=\"ql-indent-5\"><strong>When it happened</strong></li>\n    <li class=\"ql-indent-5\"><strong>Who you were with</strong></li>\n    <li class=\"ql-indent-5\"><strong>What happened</strong></li>\n  </ul>\n  <p class=\"ql-indent-5\"><strong>And explain why you feel it was important.</strong></p>\n  <p class=\"ql-indent-5\"><strong>Describe an important event in your life</strong>.&nbsp;</p>",
        },
        {
          id: 12,
          title: 'Part 3',
          description: 'Time: 4-5 minutes',
          content: "<div><p>We've been\n  talking about an important event in your life, and I'd now like to ask you some questions related to this.</p>\n  <ol>\n    <li><strong>What days are important in your country?</strong></li>\n    <li><strong>Why it is important to have national celebrations?</strong></li>\n    <li><strong>How is the way your national celebrations are celebrated now different from the way they were celebrated\n      in the past?</strong></li>\n    <li><strong>Do you think any new national celebrations will come into being in the future?</strong></li>\n    <li><strong>Are there any celebrations from other countries that you celebrate in your country?</strong></li>\n    <li><strong>What are the benefits of having events that many people around the world are celebrating on the same\n      day?</strong></li>\n  </ol>\n  <p class=\"ql-indent-5\"><br></p></div>",
        },
      ],
      created_on: '2022-05-25T00:41:21',
      updated_on: '2022-05-25T00:41:21',
    },
    {
      id: 3,
      code: 'S1',
      title: '[PREMIUM] IELTS Speaking Test: Issues You should know',
      plan: 'PREMIUM',
      status: 'DRAFT',
      speaking_sections: [
        {
          id: 7,
          title: 'Part 1',
          description: null,
          content: "<p><strong>Now,\n  in this first part of the test I'm going to ask you some questions about yourself.</strong></p>\n<p><strong>Are you a student or do you work now?</strong></p>\n<ol>\n  <li><strong>Why did you choose this course/job?</strong></li>\n  <li><strong>Talk about your daily routine.</strong></li>\n  <li><strong>Is there anything about your course/job you would like to change?</strong></li>\n  <li><strong>I’d like to move on and ask you some questions about shopping.</strong></li>\n</ol>\n<p><strong>Who does most of the shopping in your household?</strong></p>\n<ol>\n  <li><strong>What type of shopping do you like? (Why?)</strong></li>\n  <li><strong>Is shopping a popular activity in your country? (Why/why not?)</strong></li>\n  <li><strong>What type of shops do teenagers like best in your country?</strong></li>\n</ol>\n<p><strong>Let’s talk about films.</strong></p>\n<ol>\n  <li><strong>How often do you go to the cinema?</strong></li>\n  <li><strong>What type of films do you like best? (Why?)</strong></li>\n  <li><strong>What type of films don’t you like? (Why not?)</strong></li>\n</ol>\n<p><br></p>",
        },
        {
          id: 8,
          title: 'Part 2',
          description: 'Time: 3-4 minutes',
          content: "<p>Now, I'm\n  going to give you a topic and I'd like you to talk about it for 1-2 minutes.</p>\n  <p><br></p>\n  <p>Before you talk you'll have one minute to think about what you are going to say and you can make notes if you wish.\n    Do you understand?</p>\n  <p><br></p>\n  <p>Ok, here's some paper and a pencil to make notes, and here is your topic. I'd like you to describe something you\n    own which is very important to you.</p>\n  <p class=\"ql-indent-5\"><strong>Describe an important event in your life.</strong></p>\n  <p class=\"ql-indent-5\"><strong>You should say:</strong></p>\n  <ul>\n    <li class=\"ql-indent-5\"><strong>When it happened</strong></li>\n    <li class=\"ql-indent-5\"><strong>Who you were with</strong></li>\n    <li class=\"ql-indent-5\"><strong>What happened</strong></li>\n  </ul>\n  <p class=\"ql-indent-5\"><strong>And explain why you feel it was important.</strong></p>\n  <p class=\"ql-indent-5\"><strong>Describe an important event in your life</strong>.&nbsp;</p>",
        },
        {
          id: 9,
          title: 'Part 3',
          description: 'Time: 4-5 minutes',
          content: "<div><p>We've been\n  talking about an important event in your life, and I'd now like to ask you some questions related to this.</p>\n  <ol>\n    <li><strong>What days are important in your country?</strong></li>\n    <li><strong>Why it is important to have national celebrations?</strong></li>\n    <li><strong>How is the way your national celebrations are celebrated now different from the way they were celebrated\n      in the past?</strong></li>\n    <li><strong>Do you think any new national celebrations will come into being in the future?</strong></li>\n    <li><strong>Are there any celebrations from other countries that you celebrate in your country?</strong></li>\n    <li><strong>What are the benefits of having events that many people around the world are celebrating on the same\n      day?</strong></li>\n  </ol>\n  <p class=\"ql-indent-5\"><br></p></div>",
        },
      ],
      created_on: '2022-05-25T00:40:44',
      updated_on: '2022-05-25T00:40:44',
    },
    {
      id: 2,
      code: 'S1',
      title: ' IELTS Speaking Test: Issues You should know',
      plan: 'FREE',
      status: 'ACTIVE',
      speaking_sections: [
        {
          id: 4,
          title: 'Part 1',
          description: null,
          content: "<p><strong>Now,\n  in this first part of the test I'm going to ask you some questions about yourself.</strong></p>\n<p><strong>Are you a student or do you work now?</strong></p>\n<ol>\n  <li><strong>Why did you choose this course/job?</strong></li>\n  <li><strong>Talk about your daily routine.</strong></li>\n  <li><strong>Is there anything about your course/job you would like to change?</strong></li>\n  <li><strong>I’d like to move on and ask you some questions about shopping.</strong></li>\n</ol>\n<p><strong>Who does most of the shopping in your household?</strong></p>\n<ol>\n  <li><strong>What type of shopping do you like? (Why?)</strong></li>\n  <li><strong>Is shopping a popular activity in your country? (Why/why not?)</strong></li>\n  <li><strong>What type of shops do teenagers like best in your country?</strong></li>\n</ol>\n<p><strong>Let’s talk about films.</strong></p>\n<ol>\n  <li><strong>How often do you go to the cinema?</strong></li>\n  <li><strong>What type of films do you like best? (Why?)</strong></li>\n  <li><strong>What type of films don’t you like? (Why not?)</strong></li>\n</ol>\n<p><br></p>",
        },
        {
          id: 5,
          title: 'Part 2',
          description: 'Time: 3-4 minutes',
          content: "<p>Now, I'm\n  going to give you a topic and I'd like you to talk about it for 1-2 minutes.</p>\n  <p><br></p>\n  <p>Before you talk you'll have one minute to think about what you are going to say and you can make notes if you wish.\n    Do you understand?</p>\n  <p><br></p>\n  <p>Ok, here's some paper and a pencil to make notes, and here is your topic. I'd like you to describe something you\n    own which is very important to you.</p>\n  <p class=\"ql-indent-5\"><strong>Describe an important event in your life.</strong></p>\n  <p class=\"ql-indent-5\"><strong>You should say:</strong></p>\n  <ul>\n    <li class=\"ql-indent-5\"><strong>When it happened</strong></li>\n    <li class=\"ql-indent-5\"><strong>Who you were with</strong></li>\n    <li class=\"ql-indent-5\"><strong>What happened</strong></li>\n  </ul>\n  <p class=\"ql-indent-5\"><strong>And explain why you feel it was important.</strong></p>\n  <p class=\"ql-indent-5\"><strong>Describe an important event in your life</strong>.&nbsp;</p>",
        },
        {
          id: 6,
          title: 'Part 3',
          description: 'Time: 4-5 minutes',
          content: "<div><p>We've been\n  talking about an important event in your life, and I'd now like to ask you some questions related to this.</p>\n  <ol>\n    <li><strong>What days are important in your country?</strong></li>\n    <li><strong>Why it is important to have national celebrations?</strong></li>\n    <li><strong>How is the way your national celebrations are celebrated now different from the way they were celebrated\n      in the past?</strong></li>\n    <li><strong>Do you think any new national celebrations will come into being in the future?</strong></li>\n    <li><strong>Are there any celebrations from other countries that you celebrate in your country?</strong></li>\n    <li><strong>What are the benefits of having events that many people around the world are celebrating on the same\n      day?</strong></li>\n  </ol>\n  <p class=\"ql-indent-5\"><br></p></div>",
        },
      ],
      created_on: '2022-05-25T00:37:17',
      updated_on: '2022-05-25T00:37:17',
    },
    {
      id: 1,
      code: 'S1',
      title: ' IELTS Speaking Test: Issues You should know',
      plan: 'FREE',
      status: 'ACTIVE',
      speaking_sections: [
        {
          id: 1,
          title: 'Part 1',
          description: null,
          content: "<p><strong>Now,\n  in this first part of the test I'm going to ask you some questions about yourself.</strong></p>\n<p><strong>Are you a student or do you work now?</strong></p>\n<ol>\n  <li><strong>Why did you choose this course/job?</strong></li>\n  <li><strong>Talk about your daily routine.</strong></li>\n  <li><strong>Is there anything about your course/job you would like to change?</strong></li>\n  <li><strong>I’d like to move on and ask you some questions about shopping.</strong></li>\n</ol>\n<p><strong>Who does most of the shopping in your household?</strong></p>\n<ol>\n  <li><strong>What type of shopping do you like? (Why?)</strong></li>\n  <li><strong>Is shopping a popular activity in your country? (Why/why not?)</strong></li>\n  <li><strong>What type of shops do teenagers like best in your country?</strong></li>\n</ol>\n<p><strong>Let’s talk about films.</strong></p>\n<ol>\n  <li><strong>How often do you go to the cinema?</strong></li>\n  <li><strong>What type of films do you like best? (Why?)</strong></li>\n  <li><strong>What type of films don’t you like? (Why not?)</strong></li>\n</ol>\n<p><br></p>",
        },
        {
          id: 2,
          title: 'Part 2',
          description: 'Time: 3-4 minutes',
          content: "<p>Now, I'm\n  going to give you a topic and I'd like you to talk about it for 1-2 minutes.</p>\n  <p><br></p>\n  <p>Before you talk you'll have one minute to think about what you are going to say and you can make notes if you wish.\n    Do you understand?</p>\n  <p><br></p>\n  <p>Ok, here's some paper and a pencil to make notes, and here is your topic. I'd like you to describe something you\n    own which is very important to you.</p>\n  <p class=\"ql-indent-5\"><strong>Describe an important event in your life.</strong></p>\n  <p class=\"ql-indent-5\"><strong>You should say:</strong></p>\n  <ul>\n    <li class=\"ql-indent-5\"><strong>When it happened</strong></li>\n    <li class=\"ql-indent-5\"><strong>Who you were with</strong></li>\n    <li class=\"ql-indent-5\"><strong>What happened</strong></li>\n  </ul>\n  <p class=\"ql-indent-5\"><strong>And explain why you feel it was important.</strong></p>\n  <p class=\"ql-indent-5\"><strong>Describe an important event in your life</strong>.&nbsp;</p>",
        },
        {
          id: 3,
          title: 'Part 3',
          description: 'Time: 4-5 minutes',
          content: "<div><p>We've been\n  talking about an important event in your life, and I'd now like to ask you some questions related to this.</p>\n  <ol>\n    <li><strong>What days are important in your country?</strong></li>\n    <li><strong>Why it is important to have national celebrations?</strong></li>\n    <li><strong>How is the way your national celebrations are celebrated now different from the way they were celebrated\n      in the past?</strong></li>\n    <li><strong>Do you think any new national celebrations will come into being in the future?</strong></li>\n    <li><strong>Are there any celebrations from other countries that you celebrate in your country?</strong></li>\n    <li><strong>What are the benefits of having events that many people around the world are celebrating on the same\n      day?</strong></li>\n  </ol>\n  <p class=\"ql-indent-5\"><br></p></div>",
        },
      ],
      created_on: '2022-05-25T00:32:50',
      updated_on: '2022-05-25T00:32:50',
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/speakings/free').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'id',
    sortDesc = false,
    plan = null,
    status = null,
  } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.list.filter(
    test =>
    /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (test.title.toLowerCase().includes(queryLowered)) &&
            test.plan === (plan || test.plan) &&
            test.status === (status || test.status),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()
  return [
    200,
    {
      list: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// POST: Add new speakings
// ------------------------------------------------
mock.onPost('/speakings').reply(config => {
  // Get event from post data
  const test = JSON.parse(config.data)
  test.created_on = new Date()
  test.updated_on = new Date()

  const { length } = data.list
  let lastIndex = 0
  if (length) {
    lastIndex = data.list[length - 1].id
  }
  test.id = lastIndex + 1

  data.list.push(test)

  return [201, { test }]
})

mock.onGet('/speakings/premium').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'id',
    sortDesc = false,
    plan = null,
    status = null,
  } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.list.filter(
    test =>
    /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (test.title.toLowerCase().includes(queryLowered)) &&
          test.plan === (plan || test.plan) &&
          test.status === (status || test.status),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()
  return [
    200,
    {
      list: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

mock.onGet(/\/speakings\/\d+/).reply(config => {
  // Get event id from URL
  let testId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  testId = Number(testId)

  const elementIndex = data.list.findIndex(e => e.id === testId)
  const element = data.list[elementIndex]

  if (element) return [200, element]
  return [404]
})
