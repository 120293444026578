export default [
  {
    path: '/tests/writing/list/free',
    name: 'writing-list-free',
    component: () => import('@/views/tests/writing/list/WritingListFree.vue'),
  },
  {
    path: '/tests/writing/list/premium',
    name: 'writing-list-premium',
    component: () => import('@/views/tests/writing/list/WritingListPremium.vue'),
  },
  {
    path: '/tests/writing/add',
    name: 'writing-add',
    component: () => import('@/views/tests/writing/add/WritingAdd.vue'),
  },
  {
    path: '/tests/writing/edit/:id',
    name: 'writing-edit',
    component: () => import('@/views/tests/writing/edit/WritingEdit.vue'),
  },
]
